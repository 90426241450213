/* global jixAnalytics */
import $ from 'jquery';
import bootbox from 'bootbox';

import setupEmailField from "JIX/jix_email_field.js";
import { setupPasswordField } from 'JIX/jix_password_field';

const JIX = window.JIX = window.JIX || {};
const stash = window.Stash['login'];

import 'icons/signin-google.svg';
import 'icons/signin-facebook.svg';
import 'icons/signin-apple.svg';

let login_box;

/**
 * Gets the URL that should be redirected to.
 *
 * @param $button The login button as a jQuery object
 * @return The URL that should be redirected to
 */
function get_redirect_url($button) {
    return $button.data('jix-login-redirect') || stash.redirect[$button.data('jix-login')];
}

/**
 * Updates error messages in the form.
 *
 * @param $form The form as a jQuery object
 * @param res The response from the backend
 */
function update_errors($form, res) {
    $('.form-group', $form).removeClass('has-error');
    for (let name of res.invalid_fields || []) {
        $(`input[name=${name}]`, $form).closest('.form-group').addClass('has-error');
    }

    $('.alert', $form).remove();
    if (res.error_message) {
        $('<div class="alert alert-danger" role="alert"></div>')
            .text(res.error_message)
            .prependTo($form);
    }
}

/**
 * Form submit handler.
 *
 * @param $form The form as a jQuery object
 * @param type The type of login [user,companyuser]
 * @param options Options from the login button
 */
function do_submit($form, type, options) {
    const submit_url    = stash.api_urls[type];
    const redirect_url  = options['redirect'] || stash.redirect[type];
    const callback_name = options['callback_name'];
    const caller_obj    = options.caller_obj;

    let callback;
    if (callback_name) {
        callback = stash.callbacks[callback_name];
        if (callback === undefined) {
            console.error('Could not find callback "' + callback_name + '"');
        }
    }

    $.ajax({
        method: 'post',
        url:    submit_url,
        data:   $form.serialize(),
        error: () => {
            update_errors($form, { error_message: 'Unknown error', invalid_fields: [] });
        },
        success: (res) => {
            if (res && res.success) {
                if (callback) {
                    login_box.off('hide.bs.modal'); // Prevent dismiss event being sent
                    login_box.modal('hide');
                    callback(res, caller_obj);
                } else {
                    location.href = res.redirect_url || redirect_url;
                }
            } else {
                update_errors($form, res);
            }
        }
    });
}

/**
 * Click handler for login buttons.
 *
 * Shows the modal.
 *
 * @param e The DOM event.
 */
function click_handler(e) {
    e.preventDefault();
    const $this = $(this);
    const type = $this.data('jix-login');
    const opts = {
        callback_name: $this.data('jix-login-callback'),
        redirect:      $this.data('jix-login-redirect'),
        caller_obj:    $this,
    };

    if (stash.api_urls[type] === undefined) {
        throw Error("Unknown login type '" + type + "'");
    }
    JIX.Login.show_modal(type, do_submit, opts);
}

/**
 * Handles hovering over or giving focus to the login button.
 *
 * Starts loading the relevant form from the backend.
 *
 * @param e The DOM event
 */
function hover_handler() {
    const $this = $(this);
    const type  = $this.data('jix-login');
    JIX.Login.load_form(type, get_redirect_url($this));
}

function check_login_status(url) {
    return new Promise(function(resolve, reject) {
        $.ajax({
            url,
            method: 'get',
            fail: () => {
                reject(false);
            },
            success: (result) => {
                resolve(result.isLoggedIn);
            },
        });
    });
}

JIX.Login = class {
    /**
     * Shows the modal.
     *
     * Also inserts the form into the modal. If the form has not yet started
     * loading, it will be started by this function.
     *
     * @param type The type of login [user,companyuser]
     * @param submit_callback The function to call on submit
     * @param options Options from the login button
     */
    static show_modal(type, submit_callback, options) {
        const callback_name = options.callback_name;
        const caller_obj    = options.caller_obj;

        const source = typeof caller_obj.data === 'function' ? caller_obj.data('source') : caller_obj.source;

        let form = this.load_form(type, options['redirect'] || stash.redirect[type]);
        login_box = bootbox.dialog({
            message: '<div id="_jix_login_contents"></div>',
            onEscape: true,
            backdrop: true,
            size: 'md',
        });
        login_box.on("hide.bs.modal", () => {
            jixAnalytics('event', { category: 'log-ind', action: 'click', label: 'luk-boks' });
            if (callback_name) {
                const callback = stash.callbacks[callback_name];
                if (callback === undefined) {
                    console.error('Could not find callback "' + callback_name + '"');
                } else {
                    callback({ success: false, cancelled: true }, caller_obj);
                }
            }
        });
        $.when(form).done((html) => {
            const $box = $("#_jix_login_contents");
            $box.empty().append($(html.form));
            const $email_input = $('input[name=email]', $box);
            setupEmailField($email_input[0]);
            $('.jix-password-field-container', $box).each((index, htmlElement) => {
                const jixPasswordFieldId = htmlElement.dataset.id;
                setupPasswordField(jixPasswordFieldId);
            });
            $(".jix-link-create-user", $box).each((index, htmlElement) => {
                const url = new URL(htmlElement.href);
                if (source) {
                    url.searchParams.append('source', source);
                }
                htmlElement.href = url;
            });

            login_box.off("shown.bs.modal"); // Bootbox tries to focus first primary button - stop that
            login_box.on("shown.bs.modal", () => $email_input.trigger('focus'));

            const $form = $('form', $box);
            $form.on('submit', (e) => {
                e.preventDefault();
                do_submit($form, type, options);
            });
        });
    }

    /**
     * Starts loading the relevant form from the backend.
     *
     * @param type The type of login [user,companyuser]
     * @param redirect The redirect URL
     * @returns A promise for the form HTML
     */
    static load_form(type, redirect) {
        let form = stash.forms[type];

        if (form === undefined) {
            throw Error("Unknown login type '" + type + '"');
        }
        if (!form) {
            const url = stash.api_urls.forms[type] + '?request=' + encodeURIComponent(redirect);
            form = $.ajax({
                url,
                method: 'get',
                fail: () => {
                    console.error('Failed loading form "' + type + '" from backend');
                    stash.forms[type] = null;
                },
            });

            stash.forms[type] = form;
        }
        return form;
    }

    /**
     * Registers hover/click handlers for the given jQuery selector.
     *
     * @param selector A jQuery-compatible selector
     */
    static register_handlers(selector) {
        const $body = $(document.body);

        $body.on('click', selector, click_handler);
        $body.on('mouseover focus', selector, hover_handler);
    }

    /**
     * Registers a callback for use when a login is successful.
     *
     * @param name The name specified in the button's data-jix-login-callback attribute
     * @param callback A function that takes the backend response and the calling object as arguments
     */
    static register_callback(name, callback) {
        stash.callbacks[name] = callback;
    }

    /**
     * Registers a callback for use when the user is logged in.
     *
     * @param type is the user type to be logged in. either user or companyuser
     * @param callback A function that takes the backend response and the calling object as arguments
     */
    static verify_login (type, callback, args) {
        const url = stash.api_urls.check[type];
        if (!url) {
            console.error("User type " + type + " is not available");
            return false;
        }
        check_login_status(url).then(function(state) {
            if (state) {
                callback({ success: false }, args);
            } else {
                window.JIX.Login.register_callback(callback.name, callback);
                const options = {
                    callback_name: callback.name,
                    caller_obj: args,
                };
                if (args.redirect) {
                    options.redirect = args.redirect;
                }
                return window.JIX.Login.show_modal(type, callback.name, options);
            }
        }).catch(function(err) {
            console.error("Failurestate: " + err);
        });
    }

    /**
     * Determines whether a specific login type is required.
     *
     * @param type The type of login [user,companyuser]
     */
    static requires_login(type) {
        const url = stash.api_urls.check[type];
        if (!url) {
            console.error("User type " + type + " is not available");
            return false;
        }
        check_login_status(url).then(function(state) {
            return !state;
        }).catch(function(err) {
            console.error("Failurestate: " + err);
        });
    }
};

$(() => {
    JIX.Login.register_handlers("[data-jix-login]");
});
